import React, { useState,useEffect } from 'react'
import gajju from '../../Img/logo.png';
import { ToastContainer, toast } from 'react-toastify';
import '../../App.css'

const Banners = () => {
    const [file, setFile] = useState([]);
    function handleChange(e) {
        // console.log(e.target.files);
        // setFile(URL.createObjectURL(e.target.files[0]));
        Image_upload(e.target.files[0])
    }

    const Image_upload = (img)=>{
        var myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);

            var formdata = new FormData();
            formdata.append("image", img);

            var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
            };

            fetch(`${localStorage.getItem('baseurl')}/admin/upload-slider-image`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if(result.status == '1'){
                    toast(result.msg) 
                    gety_data();
                }else{
                    toast("Invalid Image Format") 
    }})
            .catch(error => console.log('error', error));
                }


            const gety_data = async()=>{
                var myHeaders = new Headers();
                myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);
                 
                var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
                };
                
                fetch(`${localStorage.getItem('baseurl')}/admin/get-slider-images`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if(result.status =='1'){ 
                setFile(result.data)
                    }else{ 
                    }
            })
                .catch(error => console.log('error', error));
            }  
            const DeleteBanner = (id)=>{
              try { 
                var myHeaders = new Headers();
                myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);

                var requestOptions = {
                  method: 'GET',
                  headers: myHeaders,
                  redirect: 'follow'
                };

               fetch(`${localStorage.getItem('baseurl')}/admin/delete-slider-image?image_id=${id}`, requestOptions)
                  .then(response => response.json())
                  .then(result => {
                    if(result.status =='1'){
                      toast(result.msg)
                      gety_data();
                    }else{
                      toast(result.msg)
                    }
                  })
                  .catch(error => console.log('error', error));
                
              } catch (error) {
                
              }
            }


                useEffect(() => {
                gety_data()
                }, [])
    
  return (
    <div>
        <ToastContainer/>

<div className='container mt-5'>
            <div className='row'>
          

            <div>
            <button onClick={()=>{document.getElementById("fileInput").click()}} className='btn btn-primary mb-3' style={{float:'right'}}> + Add New</button>
            <input
                type="file"
                id="fileInput"
                style={{ display: 'none' }}
                onChange={handleChange}
            />
            
        </div>

          {/* <div className='col-lg-12 col-sm-12 mb-5'>
            <div style={{display:'flex', justifyContent:'end'}}>
            <input type="file" onChange={handleChange} />
            <button className='btn btn-primary' > + Add New</button>
            
            </div>

            
          </div> */}


          <div className='row'>
            {/* {
                file?.map((res)=>{
                    return(
                        <>
                         <div className='col-4' style={{justifyContent:"center",alignItems:"center"}}>
                        <img src={res.path} style={{width:"50%"}}/>
                        </div>
                        </>
                    )
                })
            }
            */}
          </div>
          



                <div className='col-lg-12 col-sm-12 '>
<div style={{height:'70vh', overflowY:'scroll'}}>
                <table class="table text-white">
  <thead>
    <tr>
      <th scope="col">Sr.no</th>
      <th  colspan="3" >Banner</th>
      <th scope="col">Action</th>  
    </tr>
  </thead>
  <tbody>
    {
                      file?.map((res,index)=>{
                        return(
                            <>
                             <tr>
      <th scope="row">{index+1}</th>
      <td colspan="3"> <img src={res.path} alt='image' className="imageBanner" style={{width:'50%',height:'160px'}}/></td>
      <td>    <button className='btn btn mt-4' style={{backgroundColor:'#ffd2d2',color:'red'}}onClick={()=>{DeleteBanner(res.id)}}>Delete</button></td> 
    </tr> 
                             {/* <div className='col-4' style={{justifyContent:"center",alignItems:"center"}}>
                            <img src={res.path} style={{width:"50%"}}/>
                            </div> */}

                            </>
                        )
                    })  
    }
   
    
  </tbody>
</table>
</div>

                </div>
            </div>
        </div>
    </div>
  )
}

export default Banners