import React, { useEffect, useState } from 'react'
import '../../App.css'
import { apiurl } from '../../env'; 
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
  

function Nft() {

  const [name, setname] = useState()
  const [Amount, setAmount] = useState()
  const [version, setVersion] = useState();
  const [GetData, setGetData] = useState([]);

  const CreateNft =async()=>{
    try {
      var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");
myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);


var raw = JSON.stringify({
  "version": "1.0",
  "name": name,
  "amount": Amount
});

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
};

fetch(`${localStorage.getItem('baseurl')}admin/nft/create`, requestOptions)
  .then(response => response.json())
  .then(result => {
    console.log(result,'gajju')
    if(result.status =='1'){

    toast(result.msg); 
    NtfGetData();
    setAmount('')
    }else{
      toast(result.error)
      setAmount('')
    } 
  })
  .catch(error => console.log('error', error));
    } catch (error) {
      
    }
  }


  // get data api start

  const NtfGetData = ()=>{
    try {
      var myHeaders = new Headers();
myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')} `);

var requestOptions = {
  method: 'GET',
  headers: myHeaders,
  redirect: 'follow'
};

fetch(`${localStorage.getItem('baseurl')}admin/nft/get`, requestOptions)
  .then(response => response.json())
  .then(result => {
    if(result.status == '1'){
      setGetData(result.data)
    }
  })
  .catch(error => console.log('error', error));
      
    } catch (error) {
      
    }
  }
  // get data api end

  useEffect(() => {
    NtfGetData();
  }, [])
  

  return (
    <>
    <ToastContainer/>
    <div className='container mt-3'>
    <div className='row'>
    <div className="col-lg-6 m-auto">  
<div className='card gajju'  >
  <div className='card-body'>


  <div class="mb-3">
    <label for="exampleInputEmail1" class="form-label" style={{color:'#fff', fontWeight:'600'}}>Name</label>
    <input type="text" value={name} class="form-control py-2" id="exampleInputEmail1" placeholder='Enter Name' aria-describedby="emailHelp"  onChange={(e)=>{setname(e.target.value)}}/> 
  </div>

  {/* <div class="mb-3">
    <label for="exampleInputEmail1" class="form-label text-white">Name</label>
    <input type="text" value={name} class="form-concdol py-2" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder='Enter Name' onChange={(e)=>{setname(e.target.value)}}/> 
  </div> */}

  <div class="mb-3">
    <label for="exampleInputEmail1" class="form-label  text-white" style={{color:'#fff', fontWeight:'600'}}>Amount</label>
    <input type="number" value={Amount}  class="form-control py-2" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder='Enter Amount' onChange={(e)=>{setAmount(e.target.value)}}/> 
  </div> 


<div style={{display:'flex', justifyContent:'center'}}>

    <button onClick={()=>{CreateNft()}} style={{ backgroundColor: '#24244f', padding: '10px 30px', color: 'white', borderRadius: '8px', border: 'none',   textAlign:"center", marginTop:"13px", fontSize:'18px'}}>  Create Nft   </button>   
</div>
         
    </div>
    </div>
</div>





<div className='row mt-5'>
  <div className='col-lg-11 col-sm-12 m-auto'>
<div style={{height:'35vh', overflowY:'scroll'}}>
  <table class="table text-white">
  <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">Name</th>
      <th scope="col">Amount</th>
      <th scope="col">collected</th>
      <th scope="col">date</th>
    </tr>
  </thead>
  
  <tbody className='text-white'>

  {
    GetData?.map((res,index)=>{
      return(
        <tr>
      <th scope="row">{index+1}</th>
      <td>{res.name}</td>  
      <td>{res.amount}</td>  
      <td>{res.collected}</td>  
      <td>{res.created_at}</td>  
    </tr>
      )
    })
  }
    
  </tbody>
 
</table>
</div>
</div>
  </div>
</div>
         
    </div>
    
    </>
  )
}

export default Nft