import React, { useEffect, useState } from 'react'
import fund from '../../Img/fund.jpg'
import { ToastContainer, toast } from 'react-toastify'

function Funds() {
  const [tabs, setTabs] = useState(0);
  const [tableData, settableData] = useState([]); 
const [data, setdata] = useState([])
const [page, setpage] = useState(1)
const [username, setusername] = useState()
const [Amount, setAmount] = useState()
    const api = async() =>{
try {
    var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");
myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);

var raw = JSON.stringify({
  "page": page
});

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
};

fetch(`${localStorage.getItem('baseurl')}admin/user/page`, requestOptions)
  .then(response => response.json())
  .then(result => {console.log(result)

if (result.status == 1) {
    setdata(result.data)
}

})
  .catch(error => console.log('error', error));
} catch (error) {
    console.log(error);
}
    }


const fundsend = async () =>{
    try {
        var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");
myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);

var raw = JSON.stringify({
  "username": username,
  "fund_wallet":parseInt(Amount)
});

var requestOptions = {
  method: 'PUT',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
};

fetch(`${localStorage.getItem('baseurl')}admin/fund/add`, requestOptions)
  .then(response => response.json())
  .then(result => {console.log(result)
if (result.status == 1) {

    api()
    toast(result.message);
    setAmount(' ')
}else{
  setAmount(' ')
  toast(result.error)
}

})
  .catch(error => console.log('error', error));
    } catch (error) {
        console.log(error);
    }
}




const FundTranferHistory = () =>{
  try {
    var myHeaders = new Headers();
     myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);


  var requestOptions = {
  method: 'GET',
  headers: myHeaders,
  redirect: 'follow'
};

fetch(`${localStorage.getItem('baseurl')}admin/fund-transfer-history`, requestOptions)
  .then(response => response.json())
  .then(result => {
    if(result.status == '1'){
      settableData(result.data)
    }
  })
  .catch(error => console.log('error', error));
  } catch (error) {
    
  }
}

    useEffect(()=>{
      
        api()
        FundTranferHistory();
    },[page])
    
    
  return (
    <div>
     <ToastContainer/> 

      <div className="container mt-5">
        <div className="row">
          <div className="col-12">
            <div className="d-flex align-items-center gap-3">
              <span
                className="px-2 pb-2"
                style={{
                  color: "white",
                  borderBottom: tabs == 0 ? "2px solid #0d6efd" : "none", 
                  cursor: "pointer",
                  fontSize:'20px', fontWeight:'500'
                }}
                onClick={() => {
                  setTabs(0);
                }}
              >
              Fund Transfer
              </span>
              <span
                className="px-2 pb-2"
                style={{
                  color: "white",
                  borderBottom: tabs == 1 ? "2px solid #0d6efd" : "none", 
                  cursor: "pointer",
                  fontSize:'20px', fontWeight:'500'
                }}
                onClick={() => {
                  setTabs(1);
                }}
              >
                 Fund Transfer History
              </span>
            </div>

            <div>
              {tabs == 0 ? (
                <>
                
                <div className='container'>

<div className='row'>
  <div className='col-lg-12 col-sm-12 mb-3'>
  <button style={{ backgroundColor: 'white', padding: '4px 30px', color: '#000', borderRadius: '8px', border: 'none',float:'right' }}    data-bs-toggle="offcanvas" data-bs-target="#staticBackdrop" aria-controls="staticBackdrop"  >
  Fund Transfer by User Id
                  </button>
  </div>
</div>
<div className='row'>
  
  <div className='col-lg-12 col-md-12 col-sm-12 '>
  
  
<div style={{height:'70vh', overflowY:'scroll'}}>
      <table class="table  p-5 gajju1 ">
<thead style={{border:'1px solid gray'}}>
<tr>
<th scope="col" style={{color:"white"}} >Sr.N</th>
<th scope="col" style={{color:"white"}}> Name</th>
<th scope="col" style={{color:"white"}}>Email</th>
<th scope="col" style={{color:"white"}} >User Name</th>
<th scope="col" style={{color:"white"}}>Fund Wallet</th>
<th scope="col" style={{color:"white"}}>Fund</th>
</tr>
</thead>
<tbody >


{
  data?.map((res, index)=>{
      return(
          <tr>
          <th scope="row" style={{color:"white"}} >{index+1}</th>
          <td style={{color:"white"}}>{res.name}</td>
          <td style={{color:"white"}} >{res.email}</td>
          <td style={{color:"white"}}><div>{res.username}</div></td>
          <td style={{color:"white"}}><div>{res.fund_wallet}</div></td>
          <td><div>

         
                  <button style={{ backgroundColor: 'white', padding: '4px 30px', color: '#000', borderRadius: '8px', border: 'none' }} onClick={()=>{setusername(res.username)}}  data-bs-toggle="offcanvas" data-bs-target="#staticBackdrop" aria-controls="staticBackdrop"  >
                      Add Funds
                  </button>
              
             
           
             
              </div></td>
        </tr>
        
      )
  })
}



</tbody>
</table>

      </div>

<div style={{display:"flex",justifyContent:"center",alignItems:"center"}} >
<div>
  <button className='btn' style={{backgroundColor:"#000",color:"white"}}  onClick={()=>{setpage(page-1)}} >Back</button>
</div>
<div>
<button className='btn ms-4 ' style={{backgroundColor:"#000",color:"white"}}  onClick={()=>{setpage(page+1)}} >Next</button>
</div>
</div>


<div class="offcanvas offcanvas-start" data-bs-backdrop="static" tabindex="-1" id="staticBackdrop" aria-labelledby="staticBackdropLabel">
<div class="offcanvas-header">
<h5 class="offcanvas-title" id="staticBackdropLabel">Send</h5>
<button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
</div>
<div class="offcanvas-body">
<div>
<input class="form-control form-control-lg" type="text" placeholder="User id" value={username} aria-label=".form-control-lg example" onChange={(e)=>{setusername(e.target.value)}} />

</div>
<div className='mt-3' >


<input class="form-control form-control-lg" type="text" placeholder="Amount" aria-label=".form-control-lg example" value={Amount} onChange={(e)=>{setAmount(e.target.value)}} />

</div>
<div className='mt-3' >
<button style={{width:"100%", backgroundColor:"#B2CED1", fontSize:"20px" }} className='btn btn-primary' onClick={fundsend}  data-bs-dismiss="offcanvas" aria-label="Close" >Send</button>
</div>

<div>
  <img src={fund} style={{height:"100%",width:"100%"}} />
</div>
</div>
</div>
</div>
</div>
</div>
                </>
              ) : (
                <div className='mt-3' style={{   borderRadius: '10px' }}>
      <div className="table-responsive  py-3   " style={{backgroundColor:'transparent'}}>
        <div
          className="p-3 "
          style={{
            background: 'transparent',
            borderRadius: '10px',
            height: '63vh',
            overflowY: 'scroll',
          }}  >   
           <table className="table">
            <thead>
              <tr>
                <th style={{color:"white"}} scope="col">S.no</th>
                <th style={{color:"white"}} scope="col">To_User</th>
                <th style={{color:"white"}} scope="col">Amount</th> 
                <th style={{color:"white"}} scope="col">Date/time</th>
              </tr>
            </thead>
            <tbody >
              {tableData?.map((res,index) => {
                return(
                <tr>
                  <td style={{color:"white"}} >{index+1}</td> 
                  <td style={{color:"white"}} >{res.to_user}</td>
                  <td style={{color:"white"}} >{res.amount}</td> 
                  <td style={{color:"white"}} >{res.updated_at.split("T")[0]} {res.updated_at.split("T")[1].split(".")[0]}</td>
                </tr>
                )
              })}
            </tbody>
          </table>  
        </div>
      </div>
    </div>
              )}
            </div>
          </div>
        </div>
      </div>
     </div>
  )
}

export default Funds