import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { useMediaQuery } from "react-responsive";
import { ToastContainer, toast } from "react-toastify";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import errorAnimation from "../../Img/Animation - 1706781418107.json";
import Lottie from "lottie-react";

const Rewards = () => {
  const [tabs, setTabs] = useState(0);
  const [userName, setUserName] = useState("");
  const [amount, setAmount] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [tableData,setTableData] = useState([]);
  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  });

  const rewardTransfer = async()=>{
    try{
        var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");
myHeaders.append(
    "Authorization",
    `Bearer ${localStorage.getItem("token")}`
  );

var raw = JSON.stringify({
  "username": userName,
  "amount": amount
});

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
};

 const response = await fetch(`${localStorage.getItem("baseurl")}admin/add-user-reward`, requestOptions)

 const result = await response.json();

 if(result.status==1){
    setAmount("");
    setUserName("");
    toast.success(result.msg);
    handleClose();
 }else{
    handleClose();
    toast.error(result.error);
 }
    }catch(e){
        console.log(e)
    }
  }

  const tableApi = async()=>{
    try {
        var myHeaders = new Headers();
        myHeaders.append(
            "Authorization",
            `Bearer ${localStorage.getItem("token")}`
          );

var requestOptions = {
  method: 'GET',
  headers: myHeaders,
  redirect: 'follow'
};

 const response = await fetch(`${localStorage.getItem("baseurl")}admin/rewards-history`, requestOptions)

 const result = await response.json();

 if(result.status==1){
    setTableData(result.data);
 }
  
    } catch (error) {
        console.log(error);
    }
  }

  useEffect(() => {
    tableApi();
  }, [])
  

  return (
    <>
    <ToastContainer />
      <div className="container mt-5">
        <div className="row">
          <div className="col-12">
            <div className="d-flex align-items-center gap-3">
              <span
                className="px-2 pb-2"
                style={{
                  color: "white",
                  borderBottom: tabs == 0 ? "2px solid #0d6efd" : "none",
                  fontFamily: "Roboto",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setTabs(0);
                }}
              >
                Reward Transfer
              </span>
              <span
                className="px-2 pb-2"
                style={{
                  color: "white",
                  borderBottom: tabs == 1 ? "2px solid #0d6efd" : "none",
                  fontFamily: "Roboto",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setTabs(1);
                }}
              >
                Reward History
              </span>
            </div>

            <div>
              {tabs == 0 ? (
                <>
                <div
                  className="card mt-4 gajju1 "
                  style={{
                    border: "none",
                    borderRadius: "5px",
                    height: isMobile ? "35vh" : "30vh",
                    display: "flex",
                    justifyContent: "center",
                    backgroundColor: "rgb(94,54,135)",
                  }}
                >
                  <div className="card-body mt-3">
                    <div className="row">
                      {/* <div className='col-lg-2 col-sm-12'></div> */}
                      <div className="col-lg-6 col-sm-12 mx-auto">
                        <div className="mb-3 ">
                          <Form
                            className={`d-flex ${
                              isMobile ? "flex-column" : "flex-row"
                            } ${isMobile ? "" : "gap-3"}`}
                          >
                            <Form.Group
                              className="mb-3 flex-grow-1"
                              controlId="formBasicUserName"
                            >
                              <Form.Label className="text-white">
                                Username
                              </Form.Label>
                              <Form.Control
                                type="text"
                                value={userName}
                                placeholder="MTXXXXXXXX"
                                onChange={(e) => {
                                  setUserName(e.target.value);
                                }}
                              />
                            </Form.Group>
                            <Form.Group
                              className="mb-3 flex-grow-1"
                              controlId="formBasicAmount"
                            >
                              <Form.Label className="text-white">
                                Amount
                              </Form.Label>
                              <Form.Control
                                type="text"
                                value={amount}
                                placeholder="$"
                                onChange={(e) => {
                                  setAmount(e.target.value);
                                }}
                              />
                            </Form.Group>
                          </Form>
                        </div>
                      </div>

                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <button
                          className="Widthdrawal"
                          style={{
                            backgroundColor: "rgb(94,54,135)",
                            padding: "10px 30px",
                            color: "white",
                            borderRadius: "8px",
                            border: "none",
                            width: "30%",
                            textAlign: "center",
                            marginTop: "12px",
                          }}
                          onClick={handleShow}
                        >
                          {" "}
                          Transfer{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>


                {/* confirm modal started  */}
                <Modal show={show} onHide={handleClose} centered >
        <Modal.Header closeButton className="border-0" >
        </Modal.Header>
        <Modal.Body className="my-0 py-0" >
        <div
              className="d-flex flex-column align-items-center"
              style={{ fontFamily: "Roboto" }}
            >
              <Lottie
                style={{ height: "100px", width: "100px" }}
                className="Lottie w-40"
                animationData={errorAnimation}
                loop
                autoplay
              />
              <h5 className="text-center" >Are you sure you want to transfer to this account ?</h5>
            </div>
        </Modal.Body>
        <Modal.Footer className="border-0" >
        <Button
                on
                variant="danger"
                className="flex-grow-1"
                onClick={() => {
                  handleClose()
                }}
              >
                No
              </Button>
              <Button on variant="primary" className="flex-grow-1" onClick={rewardTransfer} >
                Yes
              </Button>
        </Modal.Footer>
      </Modal>
                {/* confirm modal end  */}
                </>
              ) : (
                <div style={{ background: '#262a43', borderRadius: '10px' }}>
      <div className="table-responsive  py-3 gajju11 " style={{backgroundColor:'transparent'}}>
        <div
          className="p-3 "
          style={{
            background: 'transparent',
            borderRadius: '10px',
            height: '63vh',
            overflowY: 'scroll',
          }}  > 
          <table className="table">
            <thead>
              <tr>
                <th style={{color:"white"}} scope="col">S.no</th>
                <th style={{color:"white"}} scope="col">User Name</th>
                <th style={{color:"white"}} scope="col">Amount</th>
                <th style={{color:"white"}} scope="col">Income Type</th>
                <th style={{color:"white"}} scope="col">Income From</th>
                <th style={{color:"white"}} scope="col">Date/time</th>
              </tr>
            </thead>
            <tbody >
              {tableData?.map((res,index) => {
                return(
                <tr>
                  <td style={{color:"white"}} >{index+1}</td>
                  <td style={{color:"white"}} >{res.username}</td>
                  <td style={{color:"white"}} >{res.amount}</td>
                  <td style={{color:"white"}} >{res.income_type}</td>
                  <td style={{color:"white"}} >{res.income_from}</td> 
                  <td style={{color:"white"}} >{res.updated_at}</td>
                </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Rewards;
