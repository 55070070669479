import React, { useEffect, useState } from 'react'
import '../../App.css'
import { apiurl } from '../../env'

const Incomes = () => {
  const [data,setdata] = useState ([])
  const [data2, setdata2] = useState([]) 
const [selectedFilter, setSelectedFilter] = useState('');

const handleFilterChange = (e) => {
  const selectedValue = e.target.value;
  setSelectedFilter(selectedValue);
  // Call dataup with the selected filter value
  dataup(selectedValue);
};

  const dataup = async (selectedValue)=>{
    try {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);
      
      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };
      
      fetch(`${localStorage.getItem('baseurl')}admin/incomes/get?income_type=${selectedValue}`, requestOptions)
        .then(response =>response.json())
        .then(result => {console.log(result)
        setdata(result.data)
        
        })
        .catch(error => console.log('error', error));
    } catch (error) {
      console.log(error);
    }
   
  }
   
  const Filtter = () =>{ 
    try {
      
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}` );
  
  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };
  
  fetch(`${apiurl}/user/income-types`, requestOptions)
    .then(response => response.json())
    .then(result => {
      if(result.status =='1'){
        setdata2(result.data)
        dataup(selectedFilter);
      }
    })
    .catch(error => console.log('error', error));
    } catch (error) {
      
    }
  }
useEffect(()=>{
  dataup()
  Filtter()
},[]) 


  return (
    <div className='container'>
    <div className='row'>
    <div className='col-lg-12'>
    <div style={{width:'100%',padding:'10px' }}>
        <h5 className='my mx-3' style={{color:"white"}} >Incomes</h5>
    </div>



    <div className='container mt-5 mb-4'>
  <div className='row'>
    <div className='col-lg-4 col-md-6 col-sm-12'>
    <select
            className="form-select py-2"
            aria-label="Default select example"
            onChange={handleFilterChange}
            value={selectedFilter}
          >
            <option value="" disabled>Select Filter</option>
            {data2?.map((res) => (
              <option key={res.value} value={res.value}>{res.name}</option>
            ))}
          </select>
    </div>
  </div>
</div>







    <div className='col-lg-12'>
<div style={{ background: '#262a43', borderRadius: '10px' }}>
      <div className="table-responsive  py-3 gajju11 " style={{backgroundColor:'transparent'}}>
        <div
          className="p-3 "
          style={{
            background: 'transparent',
            borderRadius: '10px',
            height: '63vh',
            overflowY: 'scroll',
          }}  > 
          <table className="table">
            <thead>
              <tr>
                <th style={{color:"white"}} scope="col">S.no</th>
                <th style={{color:"white"}} scope="col">User Name</th>
                <th style={{color:"white"}} scope="col">Amount</th>
                <th style={{color:"white"}} scope="col">Income Type</th>
                <th style={{color:"white"}} scope="col">Income From</th>
                <th style={{color:"white"}} scope="col">Date/time</th>
              </tr>
            </thead>
            <tbody >
              {data?.map((res,index) => (
                <tr>
                  <td style={{color:"white"}} >{index+1}</td>
                  <td style={{color:"white"}} >{res.username}</td>
                  <td style={{color:"white"}} >{res.amount}</td>
                  <td style={{color:"white"}} >{res.income_type}</td>
                  <td style={{color:"white"}} >{res.income_from}</td> 
                  <td style={{color:"white"}} >{res.updated_at}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
</div>
    </div>
    </div>    
    </div>

    
  )
}

export default Incomes