import React, { useEffect, useState } from 'react'
import '../../App.css'
const Withdraw = () => {
    const [DataGet, setDataGet] = useState([]);


    const Transitions = (value) =>{
        var myHeaders = new Headers();
myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);

var requestOptions = {
  method: 'GET',
  headers: myHeaders,
  redirect: 'follow'
};

fetch(`${localStorage.getItem('baseurl')}admin/get/transaction?txn_type=withdrawal`, requestOptions)
  .then(response => response.json())
  .then(result => {
    if(result.status==1){
        setDataGet(result.data)
    }
  })
  .catch(error => console.log('error', error));
    }

    useEffect(()=>{
        Transitions();
    },[])
  return (
    <>
    <div className='container mt-5'>
       <div className='row'>
           <div className='col-lg-11 col-sm-12 m-auto'>


<div style={{height:'80vh', overflowY:'scroll'}}>
           <table class="table   ">
 <thead>
   <tr style={{textWrap:'nowrap'}}>
     <th scope="col" style={{color:"white"}} >Sr.n</th>
     <th scope="col" style={{color:"white"}} >Name</th>
     <th scope="col" style={{color:"white"}} >Email</th>
     <th scope="col" style={{color:"white"}} >Currency </th>
     <th scope="col" style={{color:"white"}}>Transaction Id</th>
     <th scope="col" style={{color:"white"}} >Transaction Type</th>
     <th scope="col" style={{color:"white"}} >Amount</th>
     <th scope="col" style={{color:"white"}} >Crypto Amount</th>
     <th scope="col" style={{color:"white"}} >Status</th>
   </tr>
 </thead>
 <tbody  >
 {DataGet && DataGet.length > 0 ? (
    DataGet.map((res, index) => (
        <tr key={index}>
            <th style={{ color: "white" }}>{index + 1}</th>
            <th style={{ color: "white" }}>{res.name}</th>
            <th style={{ color: "white" }}>{res.email}</th>
            <th style={{ color: "white" }}>USDT BEP20</th>
            <th style={{ color: "white" }}>{res.transaction_id}</th>
            <td style={{ color: "white" }}>{res.transaction_type}</td>
            <td style={{ color: "white" }}>{res.amount}</td>
            <td style={{ color: "white" }}>{res.crypto_amount}</td> 
            <td style={{ color: "white" }}>
                {res.status === 0 ? <>Pending</> : res.status > 0 ? <>Success</> : res.status < 0 ? <>Failed</> : null}
            </td>
        </tr>
    ))
) : (
    <tr>
        <td colSpan="9" style={{ color: "white", textAlign: "center" }}>
           <h4 className='mt-4 vert-move'> No data found</h4>
        </td>
    </tr>
)}
 
  
 </tbody>
</table>
</div>
           </div>
       </div>
    </div>
   </>
  )
}

export default Withdraw