import React, { useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';

const ChangePassWord = () => {
    const [oldPass, setoldPass] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
  
    const isMatching = password === confirmPassword;
    const errorMessage = isMatching ? '' : 'Passwords do not match';
  

    const ChangePassword = ()=>{
        try {
            var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");
myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`)

var raw = JSON.stringify({
  "old_password": oldPass,
  "password": password
});

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
};

fetch(`${localStorage.getItem('baseurl')}admin/update-password`, requestOptions)
  .then(response => response.json())
  .then(result => {
    if(result.status == '1'){
        toast(result.msg)
        setoldPass('');
        setPassword('')
        setConfirmPassword('')
    }else{
        toast(result.error) 
    }
  })
  .catch(error => console.log('error', error));
        } catch (error) {
            
        }
    }
  return (
    <div>
        <ToastContainer/>
        <div className='container mt-5'>
            <div className='row'>
                <div className='col-lg-6 col-sm-12 m-auto'>
                    <div className='card'>
                        <div className='card-body'>
                        <div class="mb-3">
                    <label for="exampleFormControlInput1" class="form-label">old password</label>
                    <input type="password" class="form-control" value={oldPass} id="exampleFormControlInput1"  onChange={(e)=>{setoldPass(e.target.value)}} />
                    </div>
                    <div className={`mb-3 ${isMatching ? '' : 'has-error'}`}>
        <label htmlFor="password" className="form-label">
          New password
        </label>
        <input
          type="password"
          className="form-control"
          id="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>

      <div className={`mb-3 ${isMatching ? '' : 'has-error'}`}>
        <label htmlFor="confirmPassword" className="form-label">
          Confirm password
        </label>
        <input
          type="password"
          className="form-control"
          id="confirmPassword"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
              {errorMessage && <div className="error-message" style={{ color: 'red' }}>{errorMessage}</div>}
      </div>

                    <div>
                        <button className='btn btn-success w-100'
                         onClick={()=>{ChangePassword()}}>Submit</button>
                    </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ChangePassWord