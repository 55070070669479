import React from 'react'
import "./SideBar.css"
import logo from "../../Img/logo.png"
import { FaHome,FaUsers } from "react-icons/fa";
import { SiTask } from "react-icons/si";
import { BiTransfer,BiDetail } from "react-icons/bi";
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { AiOutlineFundProjectionScreen } from "react-icons/ai"
import { FaBitcoin } from "react-icons/fa";
import { GiCoins } from "react-icons/gi";
import { GiTakeMyMoney } from "react-icons/gi";
import { TbReportAnalytics } from "react-icons/tb";



function SideBar() {
  const Navigate = useNavigate()
  const locason = useLocation()
  
  const sty = locason.pathname == "/home" ?{backgroundColor:"black",fontWeight:'600'} : {backgroundColor:"#191c3d",fontWeight:'600'}
  const sty2 =  locason.pathname == "/plans" ? {backgroundColor:"black",fontWeight:'600'} :{backgroundColor:"#191c3d",fontWeight:'600'}
  const sty5 =  locason.pathname == "/MoneyTransfer" ? {backgroundColor:"black",fontWeight:'600'} :{backgroundColor:"#191c3d",fontWeight:'600'}
  const sty6 =  locason.pathname == "/Users" ? {backgroundColor:"black",fontWeight:'600'} :{backgroundColor:"#191c3d",fontWeight:'600'}
  const sty7 =  locason.pathname == "/Funds" ? {backgroundColor:"black",fontWeight:'600'} :{backgroundColor:"#191c3d",fontWeight:'600'}
  const sty8 =  locason.pathname == "/Incomes" ? {backgroundColor:"black",fontWeight:'600'} :{backgroundColor:"#191c3d",fontWeight:'600'}
  const sty3 =  locason.pathname == "/home" ?{color:"white"} : null
  const sty4 =  locason.pathname == "/plans" ? {color:"white"} :null
  const sty9 =  locason.pathname == "/Check" ? {backgroundColor:"black",fontWeight:'600'} :{backgroundColor:"#191c3d",fontWeight:'600'}
  const sty10 = locason.pathname == "/nft" ? {backgroundColor:"black",fontWeight:'600'} :{backgroundColor:"#191c3d",fontWeight:'600'}


  const sty21 =  locason.pathname == "/Banners" ? {backgroundColor:"black",fontWeight:'600'} :{backgroundColor:"#191c3d",fontWeight:'600'}

  const sty22 =  locason.pathname == "/ChangePassword" ? {backgroundColor:"black",fontWeight:'600'} :{backgroundColor:"#191c3d",fontWeight:'600'}
  const sty23 =  locason.pathname == "/Rewards" ? {backgroundColor:"black",fontWeight:'600'} :{backgroundColor:"#191c3d",fontWeight:'600'}
  const sty24 =  locason.pathname == "/MonthlyReport" ? {backgroundColor:"black",fontWeight:'600'} :{backgroundColor:"#191c3d",fontWeight:'600'}

  const sty25 =  locason.pathname == "/HighestEarnersData" ? {backgroundColor:"black",fontWeight:'600'} :{backgroundColor:"#191c3d",fontWeight:'600'}

  const sty92  =  locason.pathname == "/MaintenanceMode" ? {backgroundColor:"black",fontWeight:'600'} :{backgroundColor:"#191c3d",fontWeight:'600'}

  const logOut = async()=>{
    localStorage.clear()
    Navigate("/")
  }
  return (
    <div className='bg pb-1'  >

    <div className='container pt-3 desktopView'>
        <div className='row'>
            <div className='col-12'>
            <div class="d-flex flex-column flex-shrink-0  bg-warning"  /> 
      {/* <h1 className='sa' >MTFX</h1> */}
      <img src={logo} alt="" style={{height:"40px",width:"150px"}} />
  
    <hr/>
    <div style={{height:'82vh',overflowY:'scroll' }}>
    <ul class="nav nav-pills flex-column mb-auto " >
      
      <li className=' ps-2 pt-2 ' style={{textDecoration:"none",listStyle:"none",borderRadius:"10px",...sty}} >
        <NavLink to="/home" class="nav-link link-dark" style={{textDecoration:"none",color:"white",display:"flex",alignItems:"center",...sty3}}>
        <p><FaHome size={27} />
</p>
          {/* <svg class="bi me-2" width="16" height="16"><use xlink:href="#speedometer2"></use></svg> */}
          <p className='ms-2' >Dashboard</p>
        </NavLink>
      </li>
      <li className='mt-2 ps-2 pt-2 ' style={{textDecoration:"none",listStyle:"none",borderRadius:"10px",...sty2}}>
        <NavLink to="/plans" class="nav-link link-dark" style={{textDecoration:"none",color:"white",display:"flex",alignItems:"center",...sty4}}>
          {/* <svg class="bi me-2" width="16" height="16"><use xlink:href="#table"></use></svg> */}
          <p><SiTask size={27} /></p>
          <p className='ms-2' >Plans</p>
        </NavLink>
      </li>
      <li className='mt-2 ps-2 py-2 ' style={{textDecoration:"none",listStyle:"none",borderRadius:"10px",...sty5,  }}>
        {/* <NavLink to="/MoneyTransfer" class="nav-link link-dark" style={{textDecoration:"none",color:"white",display:"flex",alignItems:"center",...sty4}}> */}
           
        <div class="dropdown">
  <a class="btn btn-secondary dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false"  style={{backgroundColor:'transparent', border:'none',...sty4}}>
  <BiTransfer size={27} /> Transactions
  </a>
  

  <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
  <NavLink to="/MoneyTransfer" style={{textDecoration:'none', ...sty4}}><li><a class="dropdown-item" href="#">Deposit</a></li></NavLink> 
  <NavLink to="/Withdraw" style={{textDecoration:'none', ...sty4}}><li><a class="dropdown-item" href="#">Withdraw</a></li></NavLink>  
  <NavLink to="/P2P" style={{textDecoration:'none', ...sty4}}><li><a class="dropdown-item" href="#">P2P</a></li></NavLink>  
  </ul>
</div>
        {/* </NavLink> */} 
      </li>


      <li className='mt-2 ps-2 pt-2 ' style={{textDecoration:"none",listStyle:"none",borderRadius:"10px",...sty6}}>
        <NavLink to="/Users" class="nav-link link-dark" style={{textDecoration:"none",color:"white",display:"flex",alignItems:"center",...sty4}}>
          {/* <svg class="bi me-2" width="16" height="16"><use xlink:href="#table"></use></svg> */}
          <p><FaUsers size={27} /></p>
          <p className='ms-2' >Users</p>
        </NavLink>
      </li>


      <li className='mt-2 ps-2 pt-2 ' style={{textDecoration:"none",listStyle:"none",borderRadius:"10px",...sty8}}>
        <NavLink to="/Incomes" class="nav-link link-dark" style={{textDecoration:"none",color:"white",display:"flex",alignItems:"center",...sty4}}>
          {/* <svg class="bi me-2" width="16" height="16"><use xlink:href="#table"></use></svg> */}
           <p><FaBitcoin size={27} /></p>
          <p className='ms-2' >Incomes</p>
        </NavLink>
      </li>

      <li className='mt-2 ps-2 pt-2 ' style={{textDecoration:"none",listStyle:"none",borderRadius:"10px",...sty7}}>
        <NavLink to="/Funds" class="nav-link link-dark" style={{textDecoration:"none",color:"white",display:"flex",alignItems:"center",...sty4}}>
          {/* <svg class="bi me-2" width="16" height="16"><use xlink:href="#table"></use></svg> */}
          <p><AiOutlineFundProjectionScreen size={27} /></p>
          <p className='ms-2' >Fund Transfer</p>
        </NavLink>
      </li>

      <li className='mt-2 ps-2 pt-2 ' style={{textDecoration:"none",listStyle:"none",borderRadius:"10px",...sty23}}>
        <NavLink to="/Rewards" class="nav-link link-dark" style={{textDecoration:"none",color:"white",display:"flex",alignItems:"center",...sty4}}>
          {/* <svg class="bi me-2" width="16" height="16"><use xlink:href="#table"></use></svg> */}
          <p><GiTakeMyMoney  size={27} /></p>
          <p className='ms-2' >Reward Transfer</p>
        </NavLink>
      </li>
      
      <li className='mt-2 ps-2 pt-2 ' style={{textDecoration:"none",listStyle:"none",borderRadius:"10px",...sty9}}>
        <NavLink to="/Check" class="nav-link link-dark" style={{textDecoration:"none",color:"white",display:"flex",alignItems:"center",...sty4}}>
          {/* <svg class="bi me-2" width="16" height="16"><use xlink:href="#table"></use></svg> */}
          <p><GiCoins size={27} /></p>
          <p className='ms-2' >Jackpot Distribution </p>
        </NavLink>
      </li>

      <li className='mt-2 ps-2 pt-2 ' style={{textDecoration:"none",listStyle:"none",borderRadius:"10px",...sty24}}>
        <NavLink to="/MonthlyReport" class="nav-link link-dark" style={{textDecoration:"none",color:"white",display:"flex",alignItems:"center",...sty4}}>
          {/* <svg class="bi me-2" width="16" height="16"><use xlink:href="#table"></use></svg> */}
          <p><TbReportAnalytics  size={27} /></p>
          <p className='ms-2' >Monthly Report </p>
        </NavLink>
      </li>

      <li className='mt-2 ps-2 pt-2 ' style={{textDecoration:"none",listStyle:"none",borderRadius:"10px",...sty25}}>
        <NavLink to="/HighestEarnersData" class="nav-link link-dark" style={{textDecoration:"none",color:"white",display:"flex",alignItems:"center",...sty25}}>
          {/* <svg class="bi me-2" width="16" height="16"><use xlink:href="#table"></use></svg> */}
          <p><TbReportAnalytics  size={27} /></p>
          <p className='ms-2' > Highest Earners </p>
        </NavLink>
      </li>

      <li className='mt-2 ps-2 pt-2 ' style={{textDecoration:"none",listStyle:"none",borderRadius:"10px",...sty10}}>
        <NavLink to="/nft" class="nav-link link-dark" style={{textDecoration:"none",color:"white",display:"flex",alignItems:"center",...sty4}}>
          {/* <svg class="bi me-2" width="16" height="16"><use xlink:href="#table"></use></svg> */}
          <p><BiDetail size={27} /></p>
          <p className='ms-2' >NFT </p>
        </NavLink>
      </li>


      <li className='mt-2 ps-2 pt-2 ' style={{textDecoration:"none",listStyle:"none",borderRadius:"10px",...sty21}}>
        <NavLink to="/Banners" class="nav-link link-dark" style={{textDecoration:"none",color:"white",display:"flex",alignItems:"center",...sty21}}>
          {/* <svg class="bi me-2" width="16" height="16"><use xlink:href="#table"></use></svg> */}
          <p><FaUsers size={27} /></p>
          <p className='ms-2' >Banners</p>
        </NavLink>
      </li>

      <li className='mt-2 ps-2 pt-2 ' style={{textDecoration:"none",listStyle:"none",borderRadius:"10px",...sty22}}>
        <NavLink to="/ChangePassword" class="nav-link link-dark" style={{textDecoration:"none",color:"white",display:"flex",alignItems:"center",...sty22}}>
          {/* <svg class="bi me-2" width="16" height="16"><use xlink:href="#table"></use></svg> */}
          <p><FaUsers size={27} /></p>
          <p className='ms-2' >ChangePassword</p>
        </NavLink>
      </li>

      <li className='mt-2 ps-2 pt-2 ' style={{textDecoration:"none",listStyle:"none",borderRadius:"10px",...sty92}}>
        <NavLink to="/MaintenanceMode" class="nav-link link-dark" style={{textDecoration:"none",color:"white",display:"flex",alignItems:"center",...sty92}}>
          {/* <svg class="bi me-2" width="16" height="16"><use xlink:href="#table"></use></svg> */}
          <p><FaUsers size={27} /></p>
          <p className='ms-2' > Maintenance Mode  </p>
        </NavLink>
      </li>


      <button className='btn btn-primary mt-3' style={{width:"100%",color:"white",backgroundColor:"#1066DF"}} onClick={logOut} >Log Out </button>
    </ul>
    </div>
    <hr/>
    {/* <div class="dropdown">
      <a href="#" class="d-flex align-items-center link-dark text-decoration-none dropdown-toggle" id="dropdownUser2" data-bs-toggle="dropdown" aria-expanded="false">
        <img src="https://github.com/mdo.png" alt="" width="32" height="32" class="rounded-circle me-2"/>
        <strong>mdo</strong>
      </a>
      <ul class="dropdown-menu text-small shadow" aria-labelledby="dropdownUser2">
        <li><a class="dropdown-item" href="#">New project...</a></li>
        <li><a class="dropdown-item" href="#">Settings</a></li>
        <li><a class="dropdown-item" href="#">Profile</a></li>
        <li><hr class="dropdown-divider"/></li>
        <li><a class="dropdown-item" href="#">Sign out</a></li>
      </ul>
    </div> */}

    
            </div>
        </div>
    </div>
     
  </div>
  )
}

export default SideBar;
