import React, { useEffect, useState } from "react";
import "../../App.css";
import { ToastContainer, toast } from "react-toastify";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import errorAnimation from "../../Img/Animation - 1706781418107.json";
import Lottie from "lottie-react";
import { useMediaQuery } from "react-responsive";

const Users = () => {
  const [UserData2, setUserData2] = useState([]);

  const [userid, setuserid] = useState();
  const [activeuser, setactiveuser] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const [confirm, setConfirm] = useState(false);
  const [editUserData, setEditUserData] = useState({
    username: "",
    name: "",
    email: "",
    password: "",
  });

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (username, name, email) => {
    setEditUserData({
      username: username,
      name: name,
      email: email,
      password: "",
    });
    setShow(true);
  };

  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  });

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredData = UserData2?.filter((res) =>
    res.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const UserData = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("token")}`
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${localStorage.getItem("baseurl")}admin/get/user/data`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status == 1) {
          setUserData2(result.data);
        } else {
        }
      })
      .catch((error) => console.log("error", error));
  };

  const editDetails = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${localStorage.getItem("token")}`
      );

      var raw = JSON.stringify({
        username: editUserData.username,
        password: editUserData.password,
        name: editUserData.name,
        email: editUserData.email,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const response = await fetch(
        `${localStorage.getItem("baseurl")}admin/update-user`,
        requestOptions
      );
      const result = await response.json();

      if (result.status == 1) {
        setEditUserData({
          username: "",
          name: "",
          email: "",
          password: "",
        });
        UserData();
        toast.success(result.msg);
        handleClose();
        setConfirm(false);
      }else{
        toast.error(result.error);
        setConfirm(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const searchApi = async()=>{
    try{
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);

var requestOptions = {
  method: 'GET',
  headers: myHeaders,
  redirect: 'follow'
};

const response = await fetch(`${localStorage.getItem('baseurl')}admin/get/user/data?username=${searchQuery}`, requestOptions);
  const result = await response.json();
  if(result.status==1){
    setUserData2(result.data);
  }else if(result.status==0){
    toast.error(result.msg);
  }
    }catch(e){
      console.log(e);
    }
  }

  useEffect(() => {
    UserData();
  }, []);

  const active2 = async (i) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${localStorage.getItem("token")}`
      );

      var raw = JSON.stringify({
        userId: userid,
        active: i,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(`${localStorage.getItem("baseurl")}admin/active`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          if (result.status == 1) {
            UserData();
            toast(result.msg);
          }
        })
        .catch((error) => console.log("error", error));
    } catch (error) {
      console.log(error);
    }
  };

  const NexLoginPage = async (username, password) => {
    window.open(
      "https://app.mtfxtoken.com" +
        "/?username=" +
        username +
        "&password=" +
        password
    );
  };
  return (
    <>
      <ToastContainer />
      <div className="container mt-5">
        <div className="row">
          <div className="col-lg-11 col-sm-12  m-auto" style={{fontFamily:"Roboto"}} >
            <div className="input-group mb-3" style={{ width: isMobile?"100%":"40%" }}>
            <input
          type="search"
          className="form-control rounded"
          placeholder="Search By UserId"
          aria-label="Search"
          aria-describedby="search-addon"
          style={{ background: 'transparent', color: '#fff' }}
          value={searchQuery}
          onChange={handleSearch}
        />
        <Button variant="primary ms-3" style={{borderRadius:"0.6rem"}} onClick={searchApi} >Search</Button>
      </div>

            <div style={{ height: "76vh", overflowY: "scroll" }}>
              <table class="table">
                <thead style={{ border: "1px solid gray" }}>
                  <tr>
                    <th scope="col" style={{ color: "white" }}>
                      Sr.N
                    </th>
                    <th scope="col" style={{ color: "white" }}>
                      {" "}
                      Name
                    </th>
                    <th scope="col" style={{ color: "white" }}>
                      User Name
                    </th>
                    <th scope="col" style={{ color: "white" }}>
                      Password
                    </th>
                    <th scope="col" style={{ color: "white" }}>
                      Email
                    </th>
                    <th
                      scope="col"
                      style={{ color: "white", textWrap: "nowrap" }}
                    >
                      {" "}
                      Sponsor Name
                    </th>
                    <th scope="col" style={{ color: "white" }}>
                      {" "}
                      Sponsor Id
                    </th> 
                    <th scope="col" style={{ color: "white", textWrap:'nowrap' }}>
                      {" "}
                      Plan Status
                    </th> 
                    
                    <th scope="col" style={{ color: "white" }}>
                      Account Status 
                    </th>
                    <th scope="col" style={{ color: "white" }}>
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {UserData2?.map((res, index) => {
                    return (
                      <tr>
                        <th style={{ color: "white" }} scope="row">
                          {index + 1}
                        </th>
                        <td style={{ color: "white" }}>{res.name}</td>
                        <td style={{ color: "white" }}><div>{res.username}</div></td>
                        <td style={{ color: "white" }}><div>{res.password1}</div></td>
                        <td style={{ color: "white" }}>{res.email}</td>
                      
                        <td style={{ color: "white" }}>{res.refUser.name}</td>
                        <td style={{ color: "white" }}>{res.refUser.username}  </td>
                       
                      
                        <td style={{ color: "white" }}>{res.plan_status}</td>
                        <td>
                          <div>
                            {res.active === 1 ? (
                              <>
                                <button
                                  style={{
                                    backgroundColor: "white",
                                    padding: "4px 30px",
                                    color: "#000",
                                    borderRadius: "8px",
                                    border: "none",
                                  }}
                                  onClick={() => {
                                    setuserid(res.id);
                                    setactiveuser(res.active);
                                  }}
                                  data-bs-toggle="modal"
                                  data-bs-target="#exampleModal"
                                >
                                  Active
                                </button>
                              </>
                            ) : (
                              <button
                                style={{
                                  backgroundColor: "black",
                                  padding: "4px 30px",
                                  color: "white",
                                  borderRadius: "8px",
                                  border: "none",
                                }}
                                onClick={() => {
                                  setuserid(res.id);
                                  setactiveuser(res.active);
                                }}
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModal"
                              >
                                Inactive
                              </button>
                            )}
                          </div>
                        </td>

                        <td
                          className="d-flex align-items-center gap-2"
                          style={{ color: "white" }}
                        >
                          <button
                            className="btn btn-success"
                            onClick={() => {
                              handleShow(res.username, res.name, res.email);
                            }}
                          >
                            Edit
                          </button>
                          <button
                            className="btn btn-primary"
                            onClick={() => {
                              NexLoginPage(res.username, res.password1);
                            }}
                          >
                            Login
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleModalLabel">
                Status Update
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div
                style={{ width: "100%", backgroundColor: "", fontSize: "20px" }}
              >
                {activeuser == 1 ? (
                  <button
                    class="btn btn-primary"
                    onClick={() => {
                      active2(false);
                    }}
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    Active
                  </button>
                ) : (
                  <button
                    class="btn btn-secondary"
                    onClick={() => {
                      active2(true);
                    }}
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    UnActive
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* edit button modal started  */}
      <Modal
        show={show}
        onHide={handleClose}
        className="bg-dark"
        backdrop="static"
        centered
      >
        <Modal.Header closeButton className="border-0">
          {/* <Modal.Title>Modal heading</Modal.Title> */}
        </Modal.Header>
        <Modal.Body className="my-0 py-0">
          {confirm ? (
            <div
              className="d-flex flex-column align-items-center"
              style={{ fontFamily: "Roboto" }}
            >
              <Lottie
                style={{ height: "100px", width: "100px" }}
                className="Lottie w-40"
                animationData={errorAnimation}
                loop
                autoplay
              />
              <h5>Are you sure you want to edit details ?</h5>
            </div>
          ) : (
            <Form style={{ fontFamily: "Roboto" }}>
              <Form.Group className="mb-3" controlId="formBasicUserName">
                <Form.Label>Username</Form.Label>
                <Form.Control
                  type="text"
                  value={editUserData.username}
                  disabled
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicName">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  value={editUserData.name}
                  onChange={(e) =>
                    setEditUserData({ ...editUserData, name: e.target.value })
                  }
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  value={editUserData.email}
                  onChange={(e) =>
                    setEditUserData({ ...editUserData, email: e.target.value })
                  }
                />
                <Form.Text className="text-muted">
                  We'll never share your email with anyone else.
                </Form.Text>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Password"
                  value={editUserData.password}
                  onChange={(e) =>
                    setEditUserData({
                      ...editUserData,
                      password: e.target.value,
                    })
                  }
                />
              </Form.Group>

            </Form>
          )}
        </Modal.Body>
        <Modal.Footer
          className={`border-0 ${confirm ? "mt-5" : ""}`}
          style={{ fontFamily: "Roboto" }}
        >
          {confirm ? (
            <>
              <Button
                on
                variant="danger"
                className="flex-grow-1"
                onClick={() => {
                  setConfirm(false);
                }}
              >
                No
              </Button>
              <Button on variant="primary" className="flex-grow-1" onClick={editDetails} >
                Yes
              </Button>
            </>
          ) : (
            <>
              <Button
                on
                variant="primary"
                className="w-100"
                onClick={() => {
                  setConfirm(true);
                }}
              >
                Save Changes
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
      {/* edit button modal ended  */}
    </>
  );
};

export default Users;
