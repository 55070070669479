import React, { useEffect, useState } from 'react'
import '../../App.css'
const P2P = () => {
    const [DataGet, setDataGet] = useState([]);


    const P2PHistory = (value) =>{
        var myHeaders = new Headers();
myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);

var requestOptions = {
  method: 'GET',
  headers: myHeaders,
  redirect: 'follow'
};

fetch(`${localStorage.getItem('baseurl')}admin/get-p2p-txn`, requestOptions)
  .then(response => response.json())
  .then(result => {
    if(result.status==1){
        setDataGet(result.data)
    }
  })
  .catch(error => console.log('error', error));
    }

    useEffect(()=>{
        P2PHistory();
    },[])
  return (
    <>
    <div className='container mt-5'>
       <div className='row'>
           <div className='col-lg-11 col-sm-12 m-auto'>


<div style={{height:'80vh', overflowY:'scroll'}}>
           <table class="table   ">
 <thead>
   <tr style={{textWrap:'nowrap'}}>
     <th scope="col" style={{color:"white"}} >Sr.n</th>
     <th scope="col" style={{color:"white"}} >From_User</th>
     <th scope="col" style={{color:"white"}} >To_User</th>
     <th scope="col" style={{color:"white"}} >Amount </th> 
     <th scope="col" style={{color:"white"}} >Date </th> 
   </tr>
 </thead>
 <tbody  >
   {
       DataGet?.map((res,index)=>{
            return(
               <>
               

           <tr>
               <th style={{color:"white"}} >{index+1}</th>
               <th style={{color:"white"}}>{res.from_user}</th> 
               <th style={{color:"white"}}>{res.to_user}</th> 
               <td style={{color:"white"}}>{res.amount}</td>
               <td style={{color:"white"}}>{res.created_at}</td>
              </tr> 
              </>
            )
       })
   }
 
  
 </tbody>
</table>
</div>
           </div>
       </div>
    </div>
   </>
  )
}

export default P2P