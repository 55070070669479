import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { ToastContainer, toast } from "react-toastify";

const Report = () => {
  const [year, setyear] = useState();
  const [month, setmonth] = useState();
  const [cardData, setCardData] = useState({
    total_incomes: 0,
    total_topups: 0,
    total_withdrawal: 0,
  });
  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  });

  const api = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${localStorage.getItem("token")}`
      );

      var raw = JSON.stringify({
        month: month,
        year: year,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const response = await fetch(
        `${localStorage.getItem("baseurl")}admin/monthly-report`,
        requestOptions
      );
      const result = await response.json();

      if (result.status == 1) {
        setCardData(result);
      } else {
        toast.error(result.error);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div
              className="card mt-4 gajju1 "
              style={{
                border: "none",
                borderRadius: "5px",
                display: "flex",
                backgroundColor: "rgb(94,54,135)",
              }}
            >
              <div className="card-body">
                <div
                  className={`row align-items-center px-2 ${
                    isMobile ? "gap-3" : " "
                  }`}
                >
                  <div className="col-lg-6 col-sm-12 p-0">
                    <h3
                      className={`text-white ${isMobile ? "text-center" : ""} ${
                        isMobile ? " " : "mb-0"
                      }`}
                      style={{ fontFamily: "Roboto" }}
                    >
                      Monthly Report
                    </h3>
                  </div>
                  <div className="col-lg-2 col-sm-12">
                    <div
                      className=""
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <select
                        type="text"
                        className="p-2"
                        placeholder="select year"
                        style={{
                          textDecoration: "none",
                          width: "100%",
                          border: "none",
                          borderColor: "solid black",
                          borderRadius: "8px",
                        }}
                        onChange={(e) => {
                          setyear(e.target.value);
                        }}
                      >
                        <option value="" hidden>
                          Select Year
                        </option>
                        <option value="2024">2024</option>
                        <option value="2025">2025</option>
                        <option value="2026">2026</option>
                        <option value="2027">2027</option>
                        <option value="2028">2028</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-2 col-sm-12">
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <select
                        type="text"
                        className="p-2"
                        placeholder="select month"
                        style={{
                          textDecoration: "none",
                          width: "100%",
                          borderRadius: "8px",
                        }}
                        onChange={(e) => {
                          setmonth(e.target.value);
                        }}
                      >
                        <option value="" hidden>
                          Select Month
                        </option>
                        <option value="1">January</option>
                        <option value="2">February</option>
                        <option value="3">March</option>
                        <option value="4">April</option>
                        <option value="5">May</option>
                        <option value="6">June</option>
                        <option value="7">July</option>
                        <option value="8">August</option>
                        <option value="9">September</option>
                        <option value="10">Octomber</option>
                        <option value="11">Novmber</option>
                        <option value="12">December</option>
                      </select>
                    </div>
                  </div>
                  <div
                    onClick={api}
                    className="col-lg-2 col-sm-12 btn p-2 text-white"
                    style={{ background: "rgb(94,54,135)" }}
                  >
                    Show Result
                  </div>
                </div>
              </div>
            </div>

            <div className="col-10 m-auto">
              <div className="row">
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="card mt-5 Santosh">
                    <div className="card-body text-center">
                      <h5>Total Income</h5>
                      <h4 className="mt-3">${cardData.total_incomes}</h4>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="card mt-5 Santosh">
                    <div className="card-body text-center">
                      <h5>Total Topups</h5>
                      <h4 className="mt-3">${cardData.total_topups}</h4>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="card mt-5 Santosh">
                    <div className="card-body text-center">
                      <h5>Total Withdrawal</h5>
                      <h4 className="mt-3">${cardData.total_withdrawal}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Report;
