import React, { useEffect, useState } from 'react'
import '../../App.css'
import { ToastContainer, toast } from 'react-toastify'
import p from '../../Img/Animation - 1704521004054 (1).json'
import p2 from '../../Img/Animation - 1704520548418.json'
import Lottie from "lottie-react";


function Home() {
 
const [Data, setData] = useState('')
const [Data2, setData2] = useState('')
const [Data3, setData3] = useState('')
const [collection, setcollection] = useState('')
const [TodayTop_Up, setTodayTop_Up] = useState('')
const [WeekTop_Up, setWeekTop_Up] = useState('')
const [MonthTop_Up, setMonthTop_Up] = useState('')
// const [Wallet_Funds, setWallet_Funds] = useState('')
const [Income_Funds , setIncome_Funds] = useState('')
const [Paid_Withdrawal, setPaid_Withdrawal] = useState('')
const [pending_Withdrawal, setPending_Withdrawal] = useState('')
const [Total_Withdrawal, setTotal_Withdrawal] = useState('')
const [total_topups, settotal_topups] = useState('')
const [MagicBox, setMagicBox] = useState('')



  const GetData = () =>{
    var myHeaders = new Headers();
myHeaders.append("Authorization",  `Bearer ${localStorage.getItem('token')}`);

var requestOptions = {
  method: 'GET',
  headers: myHeaders,
  redirect: 'follow'
};

fetch(`${localStorage.getItem('baseurl')}admin/dashboard`, requestOptions)
  .then(response => response.json())
  .then(result =>  {
    if(result.status == 1){
      setData(result.user_data.total_users) 
      setData2(result.transactionCount.total) 
      setData3(result.user_data.wallet_funds) 
      setcollection(result.global_jackpot)
      setTodayTop_Up(result.todays_topups) 
      setWeekTop_Up( result.this_weeks_topups) 
      setMonthTop_Up(result.this_months_topups) 
      
      setIncome_Funds(result.user_data.income_wallet_funds) 
      setPaid_Withdrawal(result.paid_withdrawal) 
      setPending_Withdrawal(result.pending_withdrawal) 
      setTotal_Withdrawal(result.total_withdrawal) 
      settotal_topups(result.total_topups) 
      setMagicBox(result.magic_box) 
    }
  })
  .catch(error => console.log('error', error));
  }




  

  useEffect(()=>{
    GetData();
  },[])
  return (
    <>
      <ToastContainer/>

     
      <div className='container ' >
        <div className='row'>
            
            
            <div className='col-lg-10 m-auto' >
              <div className='row' style={{height:'88vh',overflowY:'scroll'}}>

                       

          <div className='col-lg-4 col-md-6 col-sm-12  '>

            <div className='card mt-5  Santosh'>
              <div className='card-body text-center'>
                <h5>Total User</h5>
                <h4 className='mt-3'>{Data}</h4>
              </div>
            </div>
          </div>

          <div className='col-lg-4 col-md-6 col-sm-12'>

            <div className='card  mt-5 Santosh'>
              <div className='card-body text-center'>
              <h5>Total Transactions</h5>
                <h4 className='mt-3'>{Data2}</h4>
              </div>
            </div>
          </div>

          <div className='col-lg-4 col-md-6 col-sm-12'>

            <div className='card mt-5 Santosh'>
              <div className='card-body text-center'>
              <h5>Total Funds Balance</h5>
                <h4 className='mt-3'>${Data3}</h4>
              </div>
            </div>
            </div>
                    <div className='col-lg-4 col-md-6 col-sm-12'>

        <div className='card mt-5 Santosh'>
          <div className='card-body text-center '>
          <h5>Current Month Jackpot </h5>
            <h4 className='mt-3'>$ {collection} </h4>
          </div>
        </div>
        </div>
              <div className='col-lg-4 col-md-6 col-sm-12'>

            <div className='card mt-5 Santosh'>
              <div className='card-body text-center'>
              <h5 class="text-capitalize"> Today Top-Up</h5>
                <h4 className='mt-3'>${TodayTop_Up}</h4>
              </div>
            </div>
            </div>  
            <div className='col-lg-4 col-md-6 col-sm-12'>

            <div className='card mt-5 Santosh'>
              <div className='card-body text-center'>
              <h5>This Week Top-Up	</h5>
                <h4 className='mt-3'>$ {WeekTop_Up}</h4>
              </div>
            </div>
            </div>
            <div className='col-lg-4 col-md-6 col-sm-12'>

            <div className='card mt-5 Santosh'>
              <div className='card-body text-center'>
              <h5>  This Month Top-Up</h5>
                <h4 className='mt-3'>$ {MonthTop_Up}</h4>
              </div>
            </div>
            </div>
            {/* <div className='col-lg-4 col-md-6 col-sm-12'>

            <div className='card mt-5 Santosh'>
              <div className='card-body text-center'>
              <h5> Wallet Funds</h5>
                <h4 className='mt-3'>$ {Data3}</h4>
              </div>
            </div>
            </div> */}


              <div className='col-lg-4 col-md-6 col-sm-12'> 
              <div className='card mt-5 Santosh'>
                <div className='card-body text-center'>
                <h5>  Income Funds</h5>
                  <h4 className='mt-3'>$ {Income_Funds}</h4>
                </div>
              </div>
              </div>  


              <div className='col-lg-4 col-md-6 col-sm-12'> 
              <div className='card mt-5 Santosh'>
                <div className='card-body text-center'>
                <h5> Paid Withdrawal</h5>
                  <h4 className='mt-3'>$  
                  {  Paid_Withdrawal !== null ? Paid_Withdrawal : 0}</h4>
                </div>
              </div>
              </div>


              <div className='col-lg-4 col-md-6 col-sm-12'> 
              <div className='card mt-5 Santosh'>
                <div className='card-body text-center'>
                <h5> Pending Withdrawal</h5>
                  <h4 className='mt-3'>$  
                  {  pending_Withdrawal !== null ? pending_Withdrawal : 0}
                    </h4>
                </div>
              </div>
              </div>


              <div className='col-lg-4 col-md-6 col-sm-12'> 
              <div className='card mt-5 Santosh'>
                <div className='card-body text-center'>
                <h5>Total Withdrawal</h5>
                  <h4 className='mt-3'>$ {Total_Withdrawal}</h4>
                </div>
              </div>
              </div>

              <div className='col-lg-4 col-md-6 col-sm-12'> 
              <div className='card mt-5 Santosh'>
                <div className='card-body text-center'>
                <h5>Total Topups</h5>
                  <h4 className='mt-3'>$ {total_topups}</h4>
                </div>
              </div>
              </div>

              <div className='col-lg-4 col-md-6 col-sm-12'> 
              <div className='card mt-5 Santosh'>
                <div className='card-body text-center'>
                <h5>Magic Box Income</h5>
                  <h4 className='mt-3'>$ {MagicBox}</h4>
                </div>
              </div>
              </div>
            </div>


            
          </div>





        
         
         {/* <div className='container mt-3' >
         <Lottie style={{height:"60vh",marginLeft:"45px",justifyItems:"flex-start"}}
                    className="Lottie w-40" // Add the class name here
                    animationData={p} // replace with your animation data
                    loop
                    autoplay
                  />
         </div> */}
         </div>
        </div>
     
 



     
    </>
  )
}

export default Home
