import React from 'react'
import { CiMenuFries } from "react-icons/ci";
import "./Head.css"
// import "./SideBar.css"
import logo from "../../Img/logo.png"
import { FaHome,FaUsers } from "react-icons/fa";
import { SiTask } from "react-icons/si";
import { BiTransfer,BiDetail } from "react-icons/bi";
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom'
import { AiOutlineFundProjectionScreen } from "react-icons/ai"
import { FaBitcoin } from "react-icons/fa";
import { GiCoins } from "react-icons/gi";
import { TbReportAnalytics } from "react-icons/tb";
import { GiTakeMyMoney } from "react-icons/gi";

function Head() {

  const Navigate = useNavigate()
  const locason = useLocation()
  const sty = locason.pathname == "/home" ?{backgroundColor:"blue"} : {backgroundColor:"#857cc2"}
  const sty2 =  locason.pathname == "/plans" ? {backgroundColor:"blue"} :{backgroundColor:"#857cc2"}
  const sty5 =  locason.pathname == "/MoneyTransfer" ? {backgroundColor:"blue"} :{backgroundColor:"#857cc2"}
  const sty6 =  locason.pathname == "/Users" ? {backgroundColor:"blue"} :{backgroundColor:"#857cc2"}
  const sty7 =  locason.pathname == "/Funds" ? {backgroundColor:"blue"} :{backgroundColor:"#857cc2"}
  const sty8 =  locason.pathname == "/Incomes" ? {backgroundColor:"blue"} :{backgroundColor:"#857cc2"}
  const sty3 = locason.pathname == "/home" ?{color:"white"} : null
  const sty4 =  locason.pathname == "/plans" ? {color:"white"} :null
  
  const sty9 =  locason.pathname == "/Check" ? {backgroundColor:"blue"} :{backgroundColor:"#857cc2"}
  const sty10 =  locason.pathname == "/nft" ? {backgroundColor:"blue"} :{backgroundColor:"#857cc2"}
  const sty11 =  locason.pathname == "/Banners" ? {backgroundColor:"blue"} :{backgroundColor:"#857cc2"}
  const sty14 =  locason.pathname == "/ChangePassword" ? {backgroundColor:"blue"} :{backgroundColor:"#857cc2"}
  const sty24 =  locason.pathname == "/MonthlyReport" ? {backgroundColor:"blue"} :{backgroundColor:"#857cc2"}
  const sty88 =  locason.pathname == "/HighestEarnersData" ? {backgroundColor:"blue"} :{backgroundColor:"#857cc2"}
  const sty23 =  locason.pathname == "/Rewards" ? {backgroundColor:"blue"} :{backgroundColor:"#857cc2"}

  const logOut = async()=>{
    localStorage.clear()
    Navigate("/")
  }
  return (
    <div className='bgh' >
      <div style={{display:"flex",justifyContent:"space-between",alignItems:"end"}} >
      <p  data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample"><CiMenuFries className='MobileView' style={{fontSize:'35px', color:'#fff', fontWeight:'600'}} /></p>
        <p className='pe-3 mt-3 ' style={{color:"white",textAlign:"end",fontSize:"1.5rem", float:'right'}} >MTFX Admin </p>
      </div>




 
<div className='MobileView'>

<div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel" style={{width:'70%'}}>
  <div class="offcanvas-header border-0">
    <h5 class="offcanvas-title" id="offcanvasExampleLabel">  <img src={logo} alt="" style={{height:"40px",width:"150px"}} /></h5>
    {/* <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button> */}
  </div>
  <div class="offcanvas-body">
    









  <div className='container pt-3'>
        <div className='row'>
            <div className='col-lg-12 col-sm-12'>
            <div class="d-flex flex-column flex-shrink-0  bg-warning"  /> 
      {/* <h1 className='sa' >MTFX</h1> */}
    
  
    <div style={{height:'80vh',overflowY:'scroll' }}>
    <ul class="nav nav-pills flex-column mb-auto "   >
      
      <li className=' ps-2 pt-2 ' data-bs-dismiss="offcanvas" aria-label="Close" style={{textDecoration:"none",listStyle:"none",borderRadius:"10px",...sty}} >
        <NavLink to="/home" class="nav-link link-dark"  style={{textDecoration:"none",color:"white",display:"flex",alignItems:"center",...sty3}} >
        <p><FaHome size={27} />
</p>
          {/* <svg class="bi me-2" width="16" height="16"><use xlink:href="#speedometer2"></use></svg> */}
          <p className='ms-2' >Dashboard</p>
        </NavLink>
      </li>
      <li className='mt-2 ps-2 pt-2 ' data-bs-dismiss="offcanvas" aria-label="Close" style={{textDecoration:"none",listStyle:"none",borderRadius:"10px",...sty2}}>
        <NavLink to="/plans" class="nav-link link-dark" style={{textDecoration:"none",color:"white",display:"flex",alignItems:"center",...sty4}}>
          {/* <svg class="bi me-2" width="16" height="16"><use xlink:href="#table"></use></svg> */}
          <p><SiTask size={27} /></p>
          <p className='ms-2' >Plans</p>
        </NavLink>
      </li>
      <li className='mt-2 ps-2 py-2 ' style={{textDecoration:"none",listStyle:"none",borderRadius:"10px",...sty5,  }}>
        {/* <NavLink to="/MoneyTransfer" class="nav-link link-dark" style={{textDecoration:"none",color:"white",display:"flex",alignItems:"center",...sty4}}> */}
           
        <div class="dropdown">
  <a class="btn btn-secondary dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false"  style={{backgroundColor:'transparent', border:'none',...sty4}}>
  <BiTransfer size={27} /> Transactions
  </a>

  <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink" style={{zIndex:'999'}}>
  <NavLink to="/MoneyTransfer"  style={{textDecoration:'none',zIndex:'999'}}><li data-bs-dismiss="offcanvas" aria-label="Close"><a class="dropdown-item"
   to="/MoneyTransfer">Deposit</a></li></NavLink> 

 <li data-bs-dismiss="offcanvas" aria-label="Close"> <NavLink to="/Withdraw"  style={{textDecoration:'none',zIndex:'999'}}> <li data-bs-dismiss="offcanvas" aria-label="Close"><a class="dropdown-item"
   to="/Withdraw">Withdraw</a></li>  </NavLink></li>  

  <NavLink to="/P2P"  style={{textDecoration:'none',zIndex:'999'}}><li data-bs-dismiss="offcanvas" aria-label="Close"><a class="dropdown-item" to="/P2P">P2P</a></li></NavLink>  
  </ul>
</div>
        {/* </NavLink> */} 
      </li>


      <li className='mt-2 ps-2 pt-2 ' data-bs-dismiss="offcanvas" aria-label="Close" style={{textDecoration:"none",listStyle:"none",borderRadius:"10px",...sty6}}>
        <NavLink to="/Users" class="nav-link link-dark" style={{textDecoration:"none",color:"white",display:"flex",alignItems:"center",...sty4}}>
          {/* <svg class="bi me-2" width="16" height="16"><use xlink:href="#table"></use></svg> */}
          <p><FaUsers size={27} /></p>
          <p className='ms-2' >Users</p>
        </NavLink>
      </li>
      <li className='mt-2 ps-2 pt-2 ' data-bs-dismiss="offcanvas" aria-label="Close" style={{textDecoration:"none",listStyle:"none",borderRadius:"10px",...sty8}}>
        <NavLink to="/Incomes" class="nav-link link-dark" style={{textDecoration:"none",color:"white",display:"flex",alignItems:"center",...sty4}}>
          {/* <svg class="bi me-2" width="16" height="16"><use xlink:href="#table"></use></svg> */}
           <p><FaBitcoin size={27} /></p>
          <p className='ms-2' >Incomes</p>
        </NavLink>
      </li>
      <li className='mt-2 ps-2 pt-2 ' data-bs-dismiss="offcanvas" aria-label="Close" style={{textDecoration:"none",listStyle:"none",borderRadius:"10px",...sty7}}>
        <NavLink to="/Funds" class="nav-link link-dark" style={{textDecoration:"none",color:"white",display:"flex",alignItems:"center",...sty4}}>
          {/* <svg class="bi me-2" width="16" height="16"><use xlink:href="#table"></use></svg> */}
          <p><AiOutlineFundProjectionScreen size={27} /></p>
          <p className='ms-2' >Fund Transfer</p>
        </NavLink>
      </li>


      <li className='mt-2 ps-2 pt-2 ' data-bs-dismiss="offcanvas" aria-label="Close" style={{textDecoration:"none",listStyle:"none",borderRadius:"10px",...sty23}}>
        <NavLink to="/Rewards" class="nav-link link-dark" style={{textDecoration:"none",color:"white",display:"flex",alignItems:"center",...sty4}}>
          {/* <svg class="bi me-2" width="16" height="16"><use xlink:href="#table"></use></svg> */}
          <p><GiTakeMyMoney  size={27} /></p>
          <p className='ms-2' >Reward Transfer</p>
        </NavLink>
      </li>
      <li className='mt-2 ps-2 pt-2 ' data-bs-dismiss="offcanvas" aria-label="Close" style={{textDecoration:"none",listStyle:"none",borderRadius:"10px",...sty9}}>
        <NavLink to="/Check" class="nav-link link-dark" style={{textDecoration:"none",color:"white",display:"flex",alignItems:"center",...sty4}}>
          {/* <svg class="bi me-2" width="16" height="16"><use xlink:href="#table"></use></svg> */}
          <p><GiCoins size={27} /></p>
          <p className='ms-2' >Jackpot Distribution </p>
        </NavLink>
      </li>


      <li className='mt-2 ps-2 pt-2 ' data-bs-dismiss="offcanvas" aria-label="Close" style={{textDecoration:"none",listStyle:"none",borderRadius:"10px",...sty24}}>
        <NavLink to="/MonthlyReport" class="nav-link link-dark" style={{textDecoration:"none",color:"white",display:"flex",alignItems:"center",...sty4}}>
          {/* <svg class="bi me-2" width="16" height="16"><use xlink:href="#table"></use></svg> */}
          <p><TbReportAnalytics  size={27} /></p>
          <p className='ms-2' >Monthly Report </p>
        </NavLink>
      </li>

      <li className='mt-2 ps-2 pt-2 ' data-bs-dismiss="offcanvas" aria-label="Close" style={{textDecoration:"none",listStyle:"none",borderRadius:"10px",...sty88}}>
        <NavLink to="/HighestEarnersData" class="nav-link link-dark" style={{textDecoration:"none",color:"white",display:"flex",alignItems:"center",...sty88}}>
          {/* <svg class="bi me-2" width="16" height="16"><use xlink:href="#table"></use></svg> */}
          <p><TbReportAnalytics  size={27} /></p>
          <p className='ms-2' >Highest Earners </p>
        </NavLink>
      </li>
      <li className='mt-2 ps-2 pt-2 ' data-bs-dismiss="offcanvas" aria-label="Close" style={{textDecoration:"none",listStyle:"none",borderRadius:"10px",...sty10}}>
        <NavLink to="/nft" class="nav-link link-dark" style={{textDecoration:"none",color:"white",display:"flex",alignItems:"center",...sty4}}>
          {/* <svg class="bi me-2" width="16" height="16"><use xlink:href="#table"></use></svg> */}
          <p><BiDetail size={27} /></p>
          <p className='ms-2' >NFT </p>
        </NavLink>
      </li>
      
      <li className='mt-2 ps-2 pt-2 ' data-bs-dismiss="offcanvas" aria-label="Close" style={{textDecoration:"none",listStyle:"none",borderRadius:"10px",...sty11}}>
        <NavLink to="/Banners" class="nav-link link-dark"  style={{textDecoration:"none",color:"white",display:"flex",alignItems:"center",...sty4}}>
          {/* <svg class="bi me-2" width="16" height="16"><use xlink:href="#table"></use></svg> */}
          <p><FaUsers size={27} /></p>
          <p className='ms-2' >Banners</p>
        </NavLink>
      </li>


 


        <li className='mt-2 ps-2 pt-2 ' data-bs-dismiss="offcanvas" aria-label="Close" style={{textDecoration:"none",listStyle:"none",borderRadius:"10px",...sty14}}>
        <NavLink to="/ChangePassword" class="nav-link link-dark" style={{textDecoration:"none",color:"white",display:"flex",alignItems:"center",...sty14}}>
          
           <p><FaBitcoin size={27} /></p>
          <p className='ms-2' >ChangePassword</p>
        </NavLink>
      </li>


      <button className='btn btn-primary mt-3' style={{width:"100%",color:"white",backgroundColor:"#1066DF"}} onClick={logOut} >Log Out </button>
    </ul>
    </div>
    <hr/>
    {/* <div class="dropdown">
      <a href="#" class="d-flex align-items-center link-dark text-decoration-none dropdown-toggle" id="dropdownUser2" data-bs-toggle="dropdown" aria-expanded="false">
        <img src="https://github.com/mdo.png" alt="" width="32" height="32" class="rounded-circle me-2"/>
        <strong>mdo</strong>
      </a>
      <ul class="dropdown-menu text-small shadow" aria-labelledby="dropdownUser2">
        <li><a class="dropdown-item" href="#">New project...</a></li>
        <li><a class="dropdown-item" href="#">Settings</a></li>
        <li><a class="dropdown-item" href="#">Profile</a></li>
        <li><hr class="dropdown-divider"/></li>
        <li><a class="dropdown-item" href="#">Sign out</a></li>
      </ul>
    </div> */}

    
            </div>
        </div>
    </div>













  </div>
</div>
     </div>


    </div>
  )
}

export default Head
