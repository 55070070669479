import React, { useState } from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import '../App.css'
import { format } from 'date-fns';
import { ToastContainer, toast } from 'react-toastify';

const HighestEarnersData = () => {
    

 
  const [data,setData]= useState([])
    const [startDate, setStartDate] = React.useState(new Date());
    
    const [EndDate, setEndDate] = React.useState(new Date());
   
    
    const handleDateChange = (date) => {
      setStartDate(date);
      console.log('Formatted Date:', format(date, 'dd/MM/yy'));

    };
    
    const handleDateChange2 = (date) => {
      setEndDate(date);
    
      console.log('Formatted EndDate:', format(date, 'dd/MM/yy'));
       

    };


    const DateData = async() =>{
        try {
             
            var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");
myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);


var raw = JSON.stringify({
  "startDate": startDate,
  "endDate": EndDate
});

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
};

fetch(`${localStorage.getItem('baseurl')}/admin/highest-earning-report`, requestOptions)
  .then(response => response.json())
  .then(result => {
    if(result.status == '1'){
        setData(result.totalIncomes)
    }
  })
  .catch(error => console.log('error', error));
        } catch (error) {
            
        }
    }

  return (
    <>
    <ToastContainer/>
        <div className='container mt-4'>
            <div className='row'>

                <h2 className='text-center mb-5 text-white'>Highest Earners</h2>
                <div className='col-lg-3 col-sm-12 col-md-12'>
                    
                    <div style={{display:'flex', justifyContent:'space-around'}}>
                       
                        <div>
                        <h5 className='text-white'> Start Date</h5>
                        <DatePicker
                            selected={startDate}
                            onChange={handleDateChange}
                            className="customDatePicker"
                            dateFormat="dd/MM/yy"
                        /> 
                        </div>
                        
                     

                </div>
                </div>

                <div className='col-lg-3 col-sm-12 col-md-12'>
                    
                    <div style={{display:'flex', justifyContent:'space-around'}}>
                       
                        
                        
                    <div>
                        <h5 className='text-white'>End Date</h5>
                        <DatePicker
                            selected={EndDate}
                            onChange={handleDateChange2}
                            className="customDatePicker"
                            dateFormat="dd/MM/yy"
                        /> 
                    </div>

                </div>
                </div>



                <div className='col-lg-3 col-sm-12 col-md-12 mt-4 pt-2'>
                <button className='btn btn-primary py-2 w-100' onClick={()=>{DateData()}}>Filter</button>
                </div>
            </div>


            <div className='row mt-4'>
                <div className='col-lg-12 col-sm-12'>

                    <div style={{height:'55vh', overflowY:'scroll'}}>
                <table class="table text-white">
  <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">UserName</th>
      <th scope="col">Name</th>
      <th scope="col">Earning</th>
    </tr>
  </thead>
  <tbody>

    {
        data?.map((res,index)=>{
            return(
                <tr>
                <th scope="row">{index+1}</th>
                <td>{res.username}</td>
                <td>{res.name}</td>
                <td>{res.earning}</td> 
              </tr> 
            )
        })
    }
    
  </tbody>
</table>    
                </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default HighestEarnersData