import React, { useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';

const MaintenanceMode = () => {

    const [Status , setStatus] = useState()

    const MaintencMode = ()=>{
        try { 
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", `Bearer ${localStorage.getItem('token')}`);

            var raw = JSON.stringify({
            "status": parseInt(Status)
            });

            var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
            };

            fetch(`${localStorage.getItem('baseurl')}admin/toggle-maintenance-mode`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if(result.status == "1"){
                    toast.success(result.msg);
                }else{
                    toast.error(result.error);
                }
            })
            .catch(error => console.log('error', error));
                    } catch (error) {
                        
        }
    }
  return (
    <>
    <ToastContainer/>
      <div className='container mt-5'>
        <div className='row'>
            <div className='col-lg-2 col-sm-12  '></div>
            <div className='col-lg-4 col-sm-12 mt-2'>
            <select class="form-select py-2" aria-label="Default select example" style={{fontSize:'18px',fontWeight:'600', backgroundColor:'#ffe5e5'}} 
            onChange={(e)=>{setStatus(e.target.value)}}>
                <option selected>Change Maintenance Mode</option>
                <option value={1}>Enable</option>
                <option value={0}>Disable</option> 
                </select>
            </div>

            <div className='col-lg-2 col-sm-12  mt-2 '>

                <button className='btn btn-primary px-4 py-2' data-bs-toggle="modal" data-bs-target="#exampleModal">Save</button>
            </div>
        </div>



  
 
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{backdropFilter:'blur(12px)'}}>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
     
      <div class="modal-body text-center">
     

     <div className='my-4'>
        <h5>Are You sure ? you want to Update Maintenance Mode </h5>
     </div>

     <div className='my-3' style={{display:'flex',justifyContent:'space-around'}}>
        <button class="btn btn-primary" data-bs-dismiss="modal" aria-label="Close">Cancel</button>
        <button className="btn btn-danger" onClick={()=>{MaintencMode()}} data-bs-dismiss="modal" aria-label="Close">Yes</button>
     </div>
      </div>
      
    </div>
  </div>
</div>
        
      </div>
     </>
  )
}

export default MaintenanceMode