import React from 'react'
import {Route, Routes} from 'react-router-dom'
import Login from '../component/Authentication/Login'
import SignUp from '../component/Authentication/SignUp'
import Home from '../component/Home/Home'
import Plans from '../component/plans/Plans'
import MoneyTransfer from '../component/Send/MoneyTransfer'
import Users from '../component/User/Users'
import Incomes from '../component/Incomes/Incomes'
import Funds from '../component/funds/Funds'
import Check from '../component/distributions/Check'
import Nft from '../component/Nft/Nft'
import Banners from '../component/Banners/Banners'
import Withdraw from '../component/Send/Withdraw'
import ChangePassWord from '../component/ChangePass/ChangePassWord'
import P2P from '../component/Send/P2P'
import Rewards from '../component/Reward/Rewards'
import Report from '../component/MonthlyReport/Report' 
import HighestEarnersData from '../Earn/HighestEarnersData'
import MaintenanceMode from '../component/Maintenance/MaintenanceMode'

function AllRoute() {
  return (
    <>
      <Routes>
        <Route path='/' element={<Login/>} />
        <Route path='/signup' element={<SignUp/>} />
        <Route path='/home' element={<Home/>} />
        <Route path='/plans' element={<Plans/>} />
        <Route path='/MoneyTransfer' element={<MoneyTransfer/>} />
        <Route path='/Users' element={<Users/>} />
        <Route path='/Incomes' element={<Incomes/>} />
        <Route path='/Funds' element={<Funds/>} />
        <Route path='/Rewards' element={<Rewards/>} />
        <Route path='/Check' element={<Check/>} />
        <Route path='/nft' element={<Nft/>} />
        <Route path='/Banners' element={<Banners/>} />
        <Route path='/Withdraw' element={<Withdraw/>} />
        <Route path='/ChangePassword' element={<ChangePassWord/>} />
        <Route path='/P2P' element={<P2P/>} />
        <Route path='/MonthlyReport' element={<Report/>} />
        <Route path='/HighestEarnersData' element={<HighestEarnersData/>} />
        <Route path='/MaintenanceMode' element={<MaintenanceMode/>} />
         
      </Routes>
    </>
  )
}

export default AllRoute
