import React ,{useState}  from 'react'

import '../../App.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Check = () => {
  const [year, setyear] = useState()
  const [month, setmonth] = useState()


  
  const api = async()=>{
    // alert (year)
    // // alert(month)

    try {
      var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");
myHeaders.append("Authorization",`Bearer ${localStorage.getItem('token')}`);

var raw = JSON.stringify({
  "selectedYear": year,
  "selectedMonth": month
});

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
};

fetch(`${localStorage.getItem('baseurl')}admin/send/distribute`, requestOptions)
  .then(response => response.json())
  .then(result => {
    if(result.status == '1'){
     toast(result.msg)
    }else{
      toast(result.msg)
    }
  } )
  .catch(error => console.log('error', error));
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <> 
    <ToastContainer/>
   <div className='container '>
   <div className='row'>
   <div className='col-lg-12'>
    <div className="you" style={{backgroundColor:'#262a43',width:'100%',padding:'10px' ,color:"white",textAlign:"center"}}>
        <h5 className='my mx-3 '>Jackpot Distribution</h5>
    </div>


    <div className='card mt-4 gajju1 '  style={{border:"none",borderRadius:"5px",height:"30vh",display:"flex",justifyContent:"center" ,backgroundColor:"rgb(94,54,135)"}}  >
    {/* <div className="mb-0 p-4">
          <label htmlFor="exampleFormControlInput1" className="f" style={{color:"white"}} >
            Enter Your Jackpot number 
          </label>
          <input
            type="text"
            className="form-control"
            id="exampleFormControlInput1"
            placeholder="Enter Daily Jackport Id"
            style={{textDecoration:"none" ,width:"50%" }}
          />

          <label htmlFor="exampleFormControlInput2" className="f mt-3" style={{color:"white"}} >
            Enter  user Daily Amount collection
          </label>
          <input
          
            type="number"
            className="form-control"
            id="exampleFormControlInput2"
            placeholder="Enter collective Amount"
            style={{textDecoration:"none", width:"50%" }}
          />
           
        </div> */}

        <div className='card-body mt-3'>
          <div className='row'> 
            <div className='col-lg-2 col-sm-12'></div>
            <div className='col-lg-4 col-sm-12'>
            
       <div className="mb-3 "  style={{display:'flex', justifyContent:'center'}} >
       <select type='text' className='p-2'  placeholder='select year' style={{textDecoration:"none", width:"100%" , border:"none",borderColor:"solid black", borderRadius:'8px'}} onChange={(e)=>{setyear(e.target.value)}} >
       <option value="">Select Year</option>
                <option value="2024">2024</option>
                <option value="2025">2025</option>
                <option value="2026">2026</option>
</select>
         
           
        </div> 
            </div>
            <div className='col-lg-4 col-sm-12'>
                     <div style={{display:'flex', justifyContent:'center'}}>
          <select  type='text' className='p-2  '  placeholder='select month'  style={{textDecoration:"none", width:"100%", borderRadius:'8px' }} onChange={(e)=>{setmonth(e.target.value)}} >
          <option value="" >Select Month</option>
                <option value="1">January</option>
                <option value="2">February</option>
                <option value="3">March</option>
                <option value="4">April</option>
                <option value="5">May</option>
                <option value="6">June</option>
                <option value="7">July</option>
                <option value="8">August</option>
                <option value="9">September</option>
                <option value="10">Octomber</option>
                <option value="11">Novmber</option>
                <option value="12">December</option>

</select>
            </div>
            </div>
            <div style={{display:'flex', justifyContent:'center'  }}>
       <button className='Widthdrawal' style={{ backgroundColor: 'rgb(94,54,135)', padding: '10px 30px', color: 'white', borderRadius: '8px', border: 'none', width:'30%',   textAlign:"center", marginTop:"12px"}}  data-bs-toggle="modal" data-bs-target="#exampleModal">  Distribute   </button>
    </div>
          
          </div>
        </div>
        
       
       
    </div>
    </div>
    
  
        
        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{backdropFilter:'blur(8px)'}}>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="exampleModalLabel">Collection Updation</h1>
        {/* <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
      </div>
      <div class="modal-body">
      <div   style={{width:"100%", backgroundColor:"", fontSize:"20px" }} >
       <div style={{display:'flex', justifyContent:'space-evenly'}}>
       <button class="btn btn-primary" data-bs-dismiss="modal" aria-label="Close" style={{backgroundColor:'transparent',color:'gray', border:'1px solid gray', padding:'8px 30px'}}>Cencel</button>
       <button class="btn btn-danger" data-bs-dismiss="modal" aria-label="Close" onClick={()=>{api()}} style={{     padding:'8px 30px'}}>Confirm</button>
       </div>
      
      </div>
      </div>
    
    </div>
  </div>
</div>
   </div> 
   </div>

   </>
  )
}

export default Check